import { Helmet, HelmetProvider } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Title } from "../assets/styles/StyleComponent";
import { userState } from "../atom";
import EventList from "../components/EventList";

function Login() {
  const user = useRecoilValue(userState);
  let navigate = useNavigate();

  return (
    <HelmetProvider>
      <>
        <Helmet>
          <title>열공응원챌린지 접수관리</title>
        </Helmet>
        <Title>열공응원챌린지 접수관리</Title>
        {!user ? (
          navigate("/login")
        ) : (
          <form>
            <EventList />
          </form>
        )}
      </>
    </HelmetProvider>
  );
}

export default Login;
