const BASE_URL = `https://www.ulsangoldenbell.com/api`;

// 접수 데이터 GET
export async function getData(nm: string) {
  return await fetch(`${BASE_URL}/app.php?name=${nm}`).then((response) =>
    response.json()
  );
}

// 수정용 접수 데이터 GET
export async function getDataOne(idx: number) {
  return await fetch(`${BASE_URL}/app2.php?idx=${idx}`).then((response) =>
    response.json()
  );
}

// 로그인 정보 GET
export async function getAdmin(log_id: string, log_pwd: string) {
  const data = {
    log_id: log_id,
    log_pwd: log_pwd,
  };
  return await fetch(`${BASE_URL}/login.php`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => response.json());
}

// 접수 건별 삭제
export async function appDel(idx?: number) {
  const data = {
    idx: idx,
  };
  return await fetch(`${BASE_URL}/app_del.php`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => response.json());
}

// 응원 건별 삭제
export async function eventDel(idx?: number) {
  const data = {
    idx: idx,
  };
  return await fetch(`${BASE_URL}/event_del.php`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => response.json());
}

// 접수 확인 GET
export async function checkData(name: string, jumin: string, mobile: string) {
  const data = {
    name: name,
    jumin: jumin,
    mobile: mobile,
  };
  return await fetch(`${BASE_URL}/check.php`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => response.json());
}

// 열공응원챌린지 데이터 GET
export async function getEvent() {
  return await fetch(`${BASE_URL}/event.php`).then((response) =>
    response.json()
  );
}

// 접수자 총 숫자 GET
export async function getCnt() {
  return await fetch(`${BASE_URL}/cnt.php`).then((response) => response.json());
}

// 열응원챌린지 POST
export async function postEvent(
  name: string,
  jumin: string,
  mobile: string,
  url: string
) {
  const data = {
    name: name,
    jumin: jumin,
    mobile: mobile,
    url: url,
  };
  return await fetch(`${BASE_URL}/post2.php`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => response.json());
}

// 열응원챌린지 확인 GET
export async function checkEvent(name: string, jumin: string, mobile: string) {
  const data = {
    name: name,
    jumin: jumin,
    mobile: mobile,
  };
  return await fetch(`${BASE_URL}/post3.php`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => response.json());
}

export async function checkModify(idx: string, file: File, file2: File) {
  const newData = new FormData();
  newData.append("idx", idx);
  newData.append("file", file);
  newData.append("file2", file2);
  return await fetch(`${BASE_URL}/post4.php`, {
    method: "POST",
    body: newData,
  }).then((response) => response.json());
}

export async function getOne(idx: number) {
  return await fetch(`${BASE_URL}/getOne.php?idx=${idx}`).then((response) =>
    response.json()
  );
}

export async function postData(
  name: string,
  jumin: string,
  gender: string,
  company: string,
  bizno1: string,
  bizno2: string,
  bizno3: string,
  zipcode: string,
  addr: string,
  addr_extra: string,
  tel: string,
  mobile: string,
  mobile_extra: string,
  email: string,
  relation: string,
  child_name: string,
  child_jumin: string,
  child_gender: string,
  school: string,
  grade: string,
  file: File,
  file2: File
) {
  const newData = new FormData();
  newData.append("name", name);
  newData.append("jumin", jumin);
  newData.append("gender", gender);
  newData.append("mobile", mobile);
  newData.append("company", company);
  newData.append("bizno1", bizno1);
  newData.append("bizno2", bizno2);
  newData.append("bizno3", bizno3);
  newData.append("addr", addr);
  newData.append("addr_extra", addr_extra);
  newData.append("tel", tel);
  newData.append("mobile_extra", mobile_extra);
  newData.append("email", email);
  newData.append("relation", relation);
  newData.append("child_name", child_name);
  newData.append("child_jumin", child_jumin);
  newData.append("child_gender", child_gender);
  newData.append("school", school);
  newData.append("grade", grade);
  newData.append("file", file);
  newData.append("file2", file2);
  return await fetch(`${BASE_URL}/post5.php`, {
    method: "POST",
    body: newData,
  }).then((response) => response.json());
}
