import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
@font-face {
    font-family: 'Pretendard';
    src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, menu, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}
/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
    display: none;
}
body {
  line-height: 1;
}
menu, ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* {
  box-sizing: border-box;
}
body {
  font-family: 'Pretendard', sans-serif;
  background-color:${(props) => props.theme.bgColor};
  color:${(props) => props.theme.textColor}
}
body,
div,
p,
h1,
h2,
h3,
h4m
th,
td,
a,
input,
button {
font-family: 'Pretendard-Medium';
letter-spacing: -1px;
word-break: keep-all;
color: #444;
}
a {
  text-decoration:none;
  color:inherit;
}

.flex {
display: flex;
gap: 1rem;
}

.smallTitle {
    fontFamily: "Pretendard-ExtraBold";
    fontSize: "1.1rem";
    color: "royalblue";
    marginTop: "24px";
}

hr {
    margin-bottom: 0;
    height: 1px;
    width: 100%;
    border-collapse: collapse;
}

input:focus{
outline: none;
}

.bigger {
  &:hover {
    scale: 1.2;
    transition: all 0.2s ease;

  }
}
`;

export default GlobalStyle;
