import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../icons";

const DownloadLink = ({ url='', fileName='', fileTitle='' }) => {
  const handleDownload = () => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName || "downloaded-file";
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching the file:", error);
      });
  };

  return (
    <>
      <FontAwesomeIcon
        icon="paperclip"
        data-src={fileName}
        onClick={handleDownload}
        style={{ cursor: "pointer" }}
        title={fileTitle}
        className="bigger"
      />
      {/* <Button type="primary" onClick={handleDownload}>
        Download Sample JSON
      </Button> */}
    </>
  );
};

export default DownloadLink;
