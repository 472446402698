import React, { ReactNode } from "react";
import "../assets/styles/Modal.css";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content2" onClick={(e) => e.stopPropagation()}>
        <div className="modal-body2">{children}</div>
        <div style={{textAlign: "right"}}>
          <button className="close-button2" onClick={onClose}>
            <span style={{ fontSize: "1rem" }}>창닫기</span> &times;
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
