import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { appDel } from "../api";

const LinkDelete = (idx: any) => {
  const clickHandler = () => {
    const response = window.confirm("정말 삭제하시겠습니까?");
    if (response) {
      const res = appDel(idx);
      console.log(res);
    }
  };
  return (
    <FontAwesomeIcon
      icon="trash-can"
      data-idx={idx}
      style={{ cursor: "pointer", color: "crimson" }}
      className="bigger"
      onClick={clickHandler}
    />
  );
};

export default LinkDelete;
