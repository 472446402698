import { BrowserRouter, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import Check from "./routes/Check";
import Main from "./routes/Main";
import Menu from "./routes/Menu";
import Receipt from "./routes/Receipt";
import Overview from "./routes/Overview";
import ReceiptInfo from "./routes/ReceiptInfo";
import Challenge from "./routes/Challenge";
import Login from "./routes/Login";
import Admin from "./routes/Admin";
import Event from "./routes/Event";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

const Cont = styled.div`
  padding: 110px 16px 16px 16px;
  width: 800px;
  min-height: calc( 100vh - 240px );
  margin: 0 auto;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

const Copy = styled.div`
  width: 800px;
  height: 80px;
  margin: 0 auto;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    letter-spacing: 0px;
    line-height: 1.5rem;
    text-align: center;
    color: white;
    font-family: 'Pretendard-light';
    letter-spacing: 0px;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
    font-size: 0.8rem;
  }
`;

function Router() {
  return (
    <BrowserRouter>
      <Container>
        <Menu />
        <Cont>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/receipt" element={<Receipt />} />
            <Route path="/receipt_info" element={<ReceiptInfo />} />
            <Route path="/check" element={<Check />} />
            <Route path="/overview" element={<Overview />} />
            <Route path="/challenge" element={<Challenge />} />
            <Route path="/login" element={<Login />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/event" element={<Event />} />
          </Routes>
        </Cont>
        <Copy>
          <p>
            Copyright &copy; 2021, ulsangoldenbell.com, All Rights Reserved.
          </p>
        </Copy>
      </Container>
    </BrowserRouter>
  );
}

export default Router;
