import { Helmet, HelmetProvider } from "react-helmet-async";
import { styled } from "styled-components";
import { Cont, ContWrapper, Title } from "../assets/styles/StyleComponent";

const Left = styled.div`
  font-family: "Pretendard-Bold";
  font-size: 1.15rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 200%;
  color: #e77575;
`;
const Right = styled.div`
  line-height: 200%;
  padding-left: 0px;
  color: #333;
`;
const Span = styled.span`
  color: royalblue;
`;
const Ol = styled.ol`
  list-style-type: numbers;
  margin-left: 16px;
`;

function ReceiptInfo() {
  return (
    <HelmetProvider>
      <>
        <Helmet>
          <title>접수방법</title>
        </Helmet>
        <Title>접수방법</Title>
        <ContWrapper>
          <Cont>
            <Left>제출서류</Left>
            <Right>
              <Ol>
                <li>참가신청서 (개인정보제공 동의 포함 온라인 기재)</li>
                <li>
                  증빙자료 (스캔 또는 사진촬영 후 파일로 업로드)
                  <br />
                  - 재직증명서(일반근로자/사업장 대표 날인 필) 또는
                  사업자등록증(개인사업자) <br />
                  - 가족관계증명서 또는 주민등록등본
                  <br />
                  ※ 스캔 또는 사진촬영 후 파일로 업로드(홈페이지내 참가신청
                  과정에서 첨부)
                  <br />※ 최근 3개월 이내 발행본
                  <br />
                  <Span>
                    ※ 참가신청 후 '신청확인' 메뉴에서 신청이 바르게 되었는지
                    반드시 확인 하시기 바랍니다.
                  </Span>
                </li>
              </Ol>
            </Right>
          </Cont>
          <Cont>
            <Left>자료집 배부</Left>
            <Right>접수마감 후 일괄 신청 주소지로 우편발송</Right>
          </Cont>
          <Cont>
            <Left>문의처</Left>
            <Right>울산상공회의소 경영향상팀 TEL.(052)228-3103~1</Right>
          </Cont>
          <p style={{ textAlign: "center", margin: "2rem 0" }}>
            행사 일정에 따라 사전 고지없이 일정이 변동 될 수 있습니다.
          </p>
        </ContWrapper>
      </>
    </HelmetProvider>
  );
}

export default ReceiptInfo;
