import { useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { styled } from "styled-components";
import { Title } from "../assets/styles/StyleComponent";
import { User, userState } from "../atom";
import { getAdmin } from "../api";

const LoginWrapper = styled.div`
  background-color: #fff;
  color: #333;
  border-radius: 16px;
  padding: 4px 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px;
  gap: 1rem;
  @media only screen and (max-width: 600px) {
    padding: 4px 20px;
  }
`;

const Con = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  input {
    width: 200px;
    margin-top: 4px;
    padding: 12px 8px;
    border: 1px solid #aaa;
    border-radius: 4px;
  }
  button {
    width: 200px;
    padding: 10px 8px;
    font-size: 1rem;
    border: 1px solid #aaa;
    border-radius: 4px;
  }
`;

type FormData = {
  log_id: string;
  log_pwd: string;
};

function Login() {
  const [formData, setFormData] = useState<FormData>({
    log_id: "",
    log_pwd: "",
  });
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const setUser = useSetRecoilState(userState);
  let navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    getAdmin(formData.log_id, formData.log_pwd).then((data) => {
      // console.log(data);
      if (data.success) {  
        localStorage.setItem("nm", data.data.name);
        localStorage.setItem("level", data.data.level);
        const user: User = {
          name: data.data.name,
          level: data.data.level,
        };
        setUser(user);
        setIsLogin(true);
      }
    });
  };
  return (
    <HelmetProvider>
      <>
        <Helmet>
          <title>로그인</title>
        </Helmet>
        <Title>로그인</Title>
        {isLogin ? (
          navigate("/admin")
        ) : (
          <form onSubmit={handleSubmit}>
            <LoginWrapper>
              <Con>
                <label>아이디</label>
                <input
                  type="text"
                  name="log_id"
                  id="log_id"
                  required
                  value={formData.log_id}
                  onChange={handleChange}
                />
              </Con>
              <Con>
                <label>비밀번호</label>
                <input
                  type="password"
                  name="log_pwd"
                  id="log_pwd"
                  required
                  value={formData.log_pwd}
                  onChange={handleChange}
                />
              </Con>
              <Con>
                <button type="submit">로그인</button>
              </Con>
            </LoginWrapper>
          </form>
        )}
      </>
    </HelmetProvider>
  );
}

export default Login;
