import { useState } from "react";
// import { appDel } from "../api";
import { styled } from "styled-components";
import { getOne } from "../api";
import Modal from "../components/Modal";

const Link = styled.a`
  text-decoration: underline;
  cursor: pointer;
`;

const Cons = styled.div`
  width: 550px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  p {
    color: #e77575;
    flex-basis: 100px;
  }
  span {
    display: block;
    text-align: left;    
  }
`;
const BtnClose = styled.button`
  border: 1px solid #e77575;
  background-color: #e77575;
  padding: 0.5rem 1rem;
  margin: 24px 0;
  border-radius: 4px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  &:hover {
    scale: 1.05;
    transition: all 0.2s ease;
  }
`;

type FormData = {
  name: string;
  jumin: string;
  gender: string;
  company: string;
  bizno: string;
  zipcode: string;
  addr: string;
  addr_extra: string;
  tel: string;
  mobile: string;
  mobile_extra: string;
  email: string;
  relation: string;
  child_name: string;
  child_jumin: string;
  child_gender: string;
  school: string;
  grade: string;
  file1: string;
  file2: string;
  created: string;
};

interface LinkProps {
  idx: number;
  company: string;
}

const LinkView = ({ idx, company }: LinkProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    name: "",
    jumin: "",
    gender: "",
    company: "",
    bizno: "",
    zipcode: "",
    addr: "",
    addr_extra: "",
    tel: "",
    mobile: "",
    mobile_extra: "",
    email: "",
    relation: "",
    child_name: "",
    child_jumin: "",
    child_gender: "",
    school: "",
    grade: "",
    file1: "",
    file2: "",
    created: "",
  });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  function done() {
    setFormData({
      name: "",
      jumin: "",
      gender: "",
      company: "",
      bizno: "",
      zipcode: "",
      addr: "",
      addr_extra: "",
      tel: "",
      mobile: "",
      mobile_extra: "",
      email: "",
      relation: "",
      child_name: "",
      child_jumin: "",
      child_gender: "",
      school: "",
      grade: "",
      file1: "",
      file2: "",
      created: "",
    });
    toggleModal();
  }
  const clickHandler = (props: { idx: number }) => {
    getOne(idx).then((data) => {
    //   console.log(data);
      setFormData({
        name: data.res.name,
        jumin: data.res.jumin,
        gender: data.res.gender,
        company: data.res.company,
        bizno: data.res.bizno,
        zipcode: data.res.zipcode,
        addr: data.res.addr,
        addr_extra: data.res.addr_extra,
        tel: data.res.tel,
        mobile: data.res.mobile,
        mobile_extra: data.res.mobile_extra,
        email: data.res.email,
        relation: data.res.relation,
        child_name: data.res.child_name,
        child_jumin: data.res.child_jumin,
        child_gender: data.res.child_gender,
        school: data.res.school,
        grade: data.res.grade,
        file1: data.res.file1,
        file2: data.res.file2,
        created: data.res.created,
      });
    });
    toggleModal();
  };

  return (
    <>
      <Link onClick={() => clickHandler({ idx })}>{company}</Link>

      <Modal isOpen={isModalOpen} onClose={toggleModal}>
        <>
          <h2 style={{ fontSize: "1.2rem" }}>상세참여정보</h2>

          <Cons>
            <p>이름</p>
            <span>{formData.name}</span>
          </Cons>
          <Cons>
            <p>주민번호</p>
            <span>
              {formData.jumin} - {formData.gender}
            </span>
          </Cons>
          <Cons>
            <p style={{ textAlign: "center" }}>직장명</p>
            <span>{formData.company}</span>
          </Cons>
          <Cons>
            <p>사업자등록번호</p>
            <span>{formData.bizno}</span>
          </Cons>
          {/* <Cons>
            <p>우편번호</p>
            <span>{formData.zipcode}</span>
          </Cons> */}
          <Cons>
            <p>주소</p>
            <span>
              {formData.addr} {formData.addr_extra}
            </span>
          </Cons>
          <Cons>
            <p>전화번호</p>
            <span>{formData.tel}</span>
          </Cons>
          <Cons>
            <p>휴대전화번호</p>
            <span>{formData.mobile}</span>
          </Cons>
          <Cons>
            <p>비상연락처</p>
            <span>{formData.mobile_extra}</span>
          </Cons>
          <Cons>
            <p>이메일주소</p>
            <span>{formData.email}</span>
          </Cons>
          <Cons>
            <p>자녀와의 관계</p>
            <span>{formData.relation}</span>
          </Cons>
          <Cons>
            <p>자녀이름</p>
            <span>{formData.child_name}</span>
          </Cons>
          <Cons>
            <p>자녀 주민번호</p>
            <span>
              {formData.child_jumin} - {formData.child_gender}
            </span>
          </Cons>
          <Cons>
            <p>학교명 및 학년</p>
            <span>
              {formData.school} {formData.grade}
            </span>
          </Cons>
          <Cons>
            <p>재직증명서</p>
            <span>{formData.file1}</span>
          </Cons>
          <Cons>
            <p>가족관계증명서</p>
            <span>{formData.file2}</span>
          </Cons>
          <Cons>
            <p>등록일</p>
            <span>{formData.created}</span>
          </Cons>
          <p style={{ textAlign: "center", margin: "20px 0" }}>
            <BtnClose type="button" onClick={done}>
              창닫기
            </BtnClose>
          </p>
        </>
      </Modal>
    </>
  );
};

export default LinkView;
