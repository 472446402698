import React, { useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { styled } from "styled-components";
import { checkEvent, postEvent } from "../api";
import BbqPic from "../assets/images/bbq.png";
import InstaPic from "../assets/images/insta.png";
import { ContWrapper, Title } from "../assets/styles/StyleComponent";
import Modal from "../components/Modal";

const Steps = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  border-bottom: 1px solid #ddd;
`;
const StepTitle = styled.div`
  flex-basis: 30%;
  font-family: "Pretendard-Bold";
  font-size: 1.5rem;
  color: #e77575;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 600px) {
    font-size: 1.3rem;
  }
`;
const StepCont = styled.div`
  flex-basis: 70%;
  line-height: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1rem 0;
`;
const P = styled.div`
  color: #e77575;
  font-size: 0.9rem;
`;
const Img = styled.img`
  width: 280px;
  border-radius: 2rem;
  border: solid 1px #ddd;
  &:hover {
    scale: 1.05;
    transition: all 0.2s ease;
  }
  @media only screen and (max-width: 600px) {
    max-width: 100%;
  }
`;
const Img2 = styled.img`
  width: 480px;
  max-width: 100%;
  border-radius: 2rem;
  border: solid 1px #ddd;
  &:hover {
    scale: 1.05;
    transition: all 0.2s ease;
  }
`;

const Btn1 = styled.button`
  border: solid 4px royalblue;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  border-radius: 2rem;
  font-size: 1.2rem;
  font-family: "Pretendard-bold";
  color: royalblue;
  cursor: pointer;
  &:hover {
    background-color: royalblue;
    color: white;
  }
  @media only screen and (max-width: 600px) {
    font-size: 0.9rem;
    padding: 4px 8px;
  }
`;
const Btn2 = styled.button`
  border: solid 4px darkorange;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  border-radius: 2rem;
  font-size: 1.2rem;
  font-family: "Pretendard-bold";
  color: darkorange;
  cursor: pointer;
  &:hover {
    background-color: darkorange;
    color: white;
  }
  @media only screen and (max-width: 600px) {
    font-size: 0.9rem;
    padding: 4px 8px;
  }
`;
const Inputs = styled.div`
  margin: 1rem 0;
  line-height: 1.5rem;
  div {
    color: #e77575;
  }
  input[type="text"] {
    padding: 0.5rem;
  }
`;
const Btn = styled.button`
  border: 1px solid #00a469;
  background-color: #00a469;
  padding: 0.5rem 1rem;
  margin: 24px 0;
  border-radius: 4px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  &:hover {
    scale: 1.05;
    transition: all 0.2s ease;
  }
`;
const BtnClose = styled.button`
  border: 1px solid #e77575;
  background-color: #e77575;
  padding: 0.5rem 1rem;
  margin: 24px 0;
  border-radius: 4px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  &:hover {
    scale: 1.05;
    transition: all 0.2s ease;
  }
`;

const Box = styled.div`
  background-color: #fff;
  color: #333;
  border-radius: 16px;
  height: 370px;
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 600px) {
    padding: 4px 20px;
  }
`;

type FormData = {
  name: string;
  jumin: string;
  mobile: string;
  url: string;
};

function Challenge() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [outLink, setOutLink] = useState("");
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const toggleModal2 = () => {
    setIsModalOpen2(!isModalOpen2);
  };
  const divRef = React.useRef<HTMLDivElement>(null);

  const autoHyphen = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.currentTarget.value = e.currentTarget.value
      .replace(/[^0-9]/g, "")
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
      .replace(/(-{1,2})$/g, "");
  };

  const [formData, setFormData] = useState<FormData>({
    name: "",
    jumin: "",
    mobile: "",
    url: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [submitted2, setSubmitted2] = useState<boolean>(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      postEvent(
        formData.name,
        formData.jumin,
        formData.mobile,
        formData.url
      ).then((data) => {
        if (data.data.success) {
          setSubmitted(true);
        } else {
          if (data.data.reason === "exist") {
            alert("이미 신청되어 있습니다.");
          }
        }
      });
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  const handleSubmit2 = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      checkEvent(formData.name, formData.jumin, formData.mobile).then(
        (data) => {
          console.log(data);
          if (data.data.success) {
              setOutLink(data.data.url.sns);
              setSubmitted2(true); 
          } else {
            if (data.data.reason === "noexist") {
              alert("신청 내역이 없습니다.");
            }

          }
        }
      );
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  function done() {
    setFormData({
      name: "",
      jumin: "",
      mobile: "",
      url: "",
    });
    setSubmitted(false);
    toggleModal();
  }

  function done2() {
    setFormData({
      name: "",
      jumin: "",
      mobile: "",
      url: "",
    });
    setSubmitted2(false);
    toggleModal2();
  }

  return (
    <HelmetProvider>
      <>
        <Helmet>
          <title>열공 응원 챌린지</title>
        </Helmet>
        <Title>열공 응원 챌린지</Title>
        <ContWrapper style={{ padding: "2rem 1rem" }}>
          <li style={{ textAlign: "center", paddingBottom: "1rem" }}>
            <Img src={InstaPic} alt="instagram" />
          </li>
          <li>
            <Steps style={{ borderTop: "solid 1px #DDD" }}>
              <StepTitle>STEP 1.</StepTitle>
              <StepCont>
                울산산업골든벨 참여 신청 후<br />
                울산산업역사이야기(자료집) 배송 받기
                <P>* 홈페이지 참가신청하기</P>
              </StepCont>
            </Steps>
          </li>
          <li>
            <Steps>
              <StepTitle>STEP 2.</StepTitle>
              <StepCont>
                자료집으로 공부하는 모습 촬영
                <br />
                가족과 함께 하는 촬영을 권장합니다.
                <P>* 재미난 촬영이 심사에 유리합니다.</P>
              </StepCont>
            </Steps>
          </li>
          <li>
            <Steps>
              <StepTitle>STEP 3.</StepTitle>
              <StepCont>
                촬영한 사진을 필수 해시태그로 인스타그램에 업로드
                <P>
                  * 필수 해시태그 #제8회울산산업골든벨과 함께 인스타그램에
                  업로드
                  <br />* 비공개 계정은 참여가 불가합니다.
                </P>
              </StepCont>
            </Steps>
          </li>
          <li>
            <Steps>
              <StepTitle>STEP 4.</StepTitle>
              <StepCont>
                이벤트에 참여하기에서 내 정보 작성 시,
                <br /> 인스타그램에 업로드한 게시물 URL을 복사해서 작성한다.
                <P>* 업로드 후 해당 게시글을 삭제하면 참여가 취소됩니다.</P>
              </StepCont>
            </Steps>
          </li>
          <li
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "1rem",
              padding: "2rem",
            }}
          >
            <Btn1 onClick={toggleModal}>이벤트 참여하기</Btn1>
            <Btn2 onClick={toggleModal2}>이벤트 참여 확인</Btn2>

            <Modal isOpen={isModalOpen2} onClose={toggleModal2}>
              <>
                <h2 style={{ fontSize: "1.2rem" }}>열공응원챌린지 참여 확인</h2>
                {submitted2 ? (
                  <div style={{margin: "5rem 0 4rem", textAlign: "center"}}>
                    <div style={{ marginBottom: "1rem" }}>
                      {formData.name}님 아래 인스타그램 주소로 접수되어
                      있습니다.
                    </div>
                    <div ref={divRef} id="url" style={{ marginBottom: "1rem" }}>
                      {outLink}
                    </div>
                    <Btn type="button" onClick={done2}>
                      확인
                    </Btn>
                  </div>
                ) : (
                  <form onSubmit={handleSubmit2}>
                    <Inputs>
                      <div>이름</div>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        style={{ width: "150px" }}
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </Inputs>
                    <Inputs>
                      <div>생년월일</div>
                      <input
                        type="text"
                        name="jumin"
                        id="jumin"
                        style={{ width: "150px" }}
                        maxLength={8}
                        pattern="[0-9]{8}"
                        value={formData.jumin}
                        placeholder={"예: 19951225"}
                        onChange={handleChange}
                        required
                      />
                    </Inputs>
                    <Inputs>
                      <div>휴대전화번호</div>
                      <input
                        type="text"
                        name="mobile"
                        id="mobile"
                        style={{ width: "150px" }}
                        maxLength={13}
                        onInput={autoHyphen}
                        pattern="[0-9]{3}-[0-9]{4}-[0-9]{4}"
                        value={formData.mobile}
                        onChange={handleChange}
                        required
                      />
                    </Inputs>
                    <div style={{ textAlign: "center", margin: "20px 0" }}>
                      <Btn type="submit">확인하기</Btn>{" "}
                      <BtnClose type="button" onClick={done2}>
                        창닫기
                      </BtnClose>
                    </div>
                  </form>
                )}
              </>
            </Modal>

            <Modal isOpen={isModalOpen} onClose={toggleModal}>
              <>
                <h2 style={{ fontSize: "1.2rem" }}>열공응원챌린지 참여</h2>
                {submitted ? (
                  <Box>
                    <div style={{ marginBottom: "2rem" }}>
                      {formData.name}님 접수되었습니다.
                    </div>
                    <Btn type="button" onClick={done}>
                      확인
                    </Btn>
                  </Box>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <Inputs>
                      <div>이름</div>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        style={{ width: "150px" }}
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </Inputs>
                    <Inputs>
                      <div>생년월일</div>
                      <input
                        type="text"
                        name="jumin"
                        id="jumin"
                        style={{ width: "150px" }}
                        maxLength={8}
                        pattern="[0-9]{8}"
                        value={formData.jumin}
                        placeholder={"예: 19951225"}
                        onChange={handleChange}
                        required
                      />
                    </Inputs>
                    <Inputs>
                      <div>휴대전화번호</div>
                      <input
                        type="text"
                        name="mobile"
                        id="mobile"
                        style={{ width: "150px" }}
                        maxLength={13}
                        onInput={autoHyphen}
                        pattern="[0-9]{3}-[0-9]{4}-[0-9]{4}"
                        value={formData.mobile}
                        onChange={handleChange}
                        required
                      />
                    </Inputs>
                    <Inputs>
                      <div>인스타그램 페이지주소</div>
                      <input
                        type="text"
                        name="url"
                        id="url"
                        style={{ width: "100%" }}
                        value={formData.url}
                        onChange={handleChange}
                        required
                      />
                    </Inputs>
                    <Inputs>
                      <div>초상권 사용동의</div>
                      <p style={{ fontSize: "0.9rem", letterSpacing: "-1px" }}>
                        본인은 제출된 저작물(사진)을 아래에 용도에 한하여
                        초상권을 사용 권리를 울산상공회소에게 부여합니다. <br />
                        사용처 : [울산산업골든벨 홍보를 위한 온/오프라인 홍보물
                        제작 및 상영, 사진전시 등]
                      </p>
                      <br />
                      상기 초상권 사용에 동의하십니까?
                      <br />
                      <label>
                        <input
                          type="checkbox"
                          name="agree"
                          id="agree_y"
                          value="y"
                          required
                        />{" "}
                        동의함
                      </label>
                      {/* </label>{" "}
                      <label>
                        <input
                          type="radio"
                          name="agree"
                          id="agree_y"
                          value="n"
                          checked
                        />{" "}
                        동의하지않음
                      </label> */}
                    </Inputs>
                    <div style={{ textAlign: "center", margin: "20px 0" }}>
                      <Btn type="submit">접수하기</Btn>{" "}
                      <BtnClose type="button" onClick={done}>
                        창닫기
                      </BtnClose>
                    </div>
                  </form>
                )}
              </>
            </Modal>
          </li>
          <li style={{ textAlign: "center" }}>
            <Img2 src={BbqPic} alt="instagram" />
          </li>
          <div style={{ padding: "2rem", lineHeight: "1.5rem" }}>
            <h3
              style={{
                fontSize: "1.2rem",
                fontFamily: "Pretendard-Bold",
                color: "#e77575",
                marginBottom: "0.4rem",
              }}
            >
              이벤트 안내
            </h3>
            <ul style={{ listStyleType: "disc", marginLeft: "16px" }}>
              <li>
                당첨자 발표는 본 홈페이지 공지사항에서 확인
                부탁드립니다.(행사종료 후 1주일)
              </li>
              <li>
                당첨자 발표 후 1개월 이내 수령하지 않으면 당첨이 취소될 수
                있습니다.
              </li>
              <li>수집된 개인정보는 상품 증정 및 발송 용도로만 사용됩니다.</li>
              <li>
                잘못된 개인정보 입력으로 이벤트 등록이 누락됐을 경우 주최측에서
                책임지지 않습니다.
              </li>
              <li>
                당첨자 발표 및 경품은 내부 사정에 따라 사전 고지없이 변동 될 수
                있습니다.
              </li>
            </ul>
          </div>
        </ContWrapper>
      </>
    </HelmetProvider>
  );
}

export default Challenge;
