import { styled } from "styled-components";

export const Title = styled.h3`
  font-family: "Pretendard-Bold";
  font-size: 2rem;
  margin: 24px 0;
  color: gold;
  text-shadow: 0px 0px 10px #000;
  text-align: center;
  @media only screen and (max-width: 600px) {
    margin: 4px 0 16px;
    font-size: 1.5rem;
  }
`;

export const ContWrapper = styled.ul`
  background-color: #fff;
  color: #333;
  border-radius: 16px;
  padding: 4px 48px;

  @media only screen and (max-width: 600px) {
    padding: 4px 20px;
  }
`;

export const Cont = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  margin: 24px 0;
`;
