import { useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { styled } from "styled-components";
import { checkData } from "../api";
import { Cont, ContWrapper, Title } from "../assets/styles/StyleComponent";
import RecieptModify from "./RecieptModify";

type FormData = {
  name: string;
  jumin: string;
  mobile: string;
};

const Left = styled.div`
  font-family: "Pretendard-Bold";
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 200%;
  color: #e77575;
`;
const Right = styled.div`
  line-height: 200%;
  padding-left: 0px;
  color: #333;
`;
const Input = styled.input`
  padding: 8px;
  border: 1px solid silver;
  border-radius: 4px;
  font-size: 1rem;
  max-width: 100%;
`;

const Btn = styled.button`
  border: 1px solid green;
  background-color: green;
  padding: 0.5rem 1rem;
  margin: 24px 0;
  border-radius: 4px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
`;

const Box = styled.div`
  background-color: #fff;
  color: #333;
  border-radius: 16px;
  height: 400px;
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  @media only screen and (max-width: 600px) {
    padding: 4px 20px;
  }
`;

function Check() {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    jumin: "",
    mobile: "",
  });

  const [submitted, setSubmitted] = useState<boolean>(false);
  const [idx, setIdx] = useState<number>(0);
  const [haveIdx, setHaveIdx] = useState<number>(0);
  const [dt, setDt] = useState<string>("");
  const [isMajor, setMajor] = useState<string>("참가자");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    checkData(formData.name, formData.jumin, formData.mobile).then((data) => {
      // console.log(data);
      if (data.data.success) {
        setDt(data.data.dt);
        setIdx(data.data.idx);
        data.data.is_major === "y" ? setMajor("참가자") : setMajor("예비명단으");
        setSubmitted(true);
      } else {
        alert("입력하신 정보로 접수된 내역이 없습니다.");
      }
    });
  };

  const autoHyphen = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.currentTarget.value = e.currentTarget.value
      .replace(/[^0-9]/g, "")
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
      .replace(/(-{1,2})$/g, "");
  };

  const handleIdx = (e: React.MouseEvent<HTMLButtonElement>) => {
    const midx = e?.currentTarget.dataset.idx;
    // console.log(midx);
    setHaveIdx(Number(midx));
  }
  return (
    <HelmetProvider>
      <>
        <Helmet>
          <title>접수확인</title>
        </Helmet>
        <Title>접수확인</Title>
        {
        haveIdx ? (
          <RecieptModify idx={idx} />
        ) : (
        submitted ? (
          <Box>
            <p>
              {formData.name}님 {isMajor}로 접수되어 있습니다.
            </p>
            <p>접수일시 : {dt}</p>
            { idx < 58 ? <p><Btn data-idx={idx} onClick={handleIdx}>수정하기</Btn></p> : <></> }
          </Box>
        ) : (
          <form onSubmit={handleSubmit}>
            <ContWrapper>
              <Cont>
                <Left>참가자이름</Left>
                <Right>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    required
                    value={formData.name}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                  />
                </Right>
              </Cont>
              <Cont>
                <Left>주민등록번호 앞자리</Left>
                <Right>
                  <Input
                    type="text"
                    name="jumin"
                    id="jumin"
                    size={7}
                    maxLength={6}
                    required
                    value={formData.jumin}
                    onChange={handleChange}
                    pattern="[0-9]{6}"
                  />
                </Right>
              </Cont>
              <Cont>
                <Left>휴대전화번호</Left>
                <Right>
                  <Input
                    type="text"
                    name="mobile"
                    id="mobile"
                    required
                    maxLength={13}
                    style={{ width: "100%" }}
                    value={formData.mobile}
                    onChange={handleChange}
                    onInput={autoHyphen}
                    pattern="[0-9]{3}-[0-9]{4}-[0-9]{4}"
                  />
                </Right>
              </Cont>
              <Cont style={{ textAlign: "center", margin: "20px 0" }}>
                <Btn type="submit">접수확인</Btn>
              </Cont>
            </ContWrapper>
          </form>
        )
      )}
      </>
    </HelmetProvider>
  );
}

export default Check;
