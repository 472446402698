import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
const { persistAtom } = recoilPersist();

export type User = {
  name: string;
  level: string;
};

export const userState = atom<User | null>({
  key: "userState",
  default: null,
  effects_UNSTABLE: [persistAtom],
});


export const searchQuery = atom<string | null>({
  key: "userQuery",
  default: null,
  effects_UNSTABLE: [persistAtom],
});