import { useDaumPostcodePopup } from "react-daum-postcode";
import { styled } from "styled-components";
//나머지 부분 생략

interface Props {
  scriptUrl?: string;
  setFormData: any;
}

const Button = styled.button`
  padding: 9px;
  font-size: 0.9rem;
  background-color: #abcb3e;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
`;

export default function PharmAddress({ scriptUrl, setFormData }: Props) {
  const open = useDaumPostcodePopup(scriptUrl);

  const handleComplete = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    //주소값을 상태값으로..
    setFormData((prev: any) => ({ ...prev, addr: fullAddress }));
    setFormData((prev: any) => ({ ...prev, zipcode: data.zonecode }));
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    open({ onComplete: handleComplete });
    e.preventDefault();
  };
  return (
    <div>
      <Button onClick={handleClick}>주소 찾기</Button>
    </div>
  );
}
