import React, { useState } from "react";
import { useQuery } from "react-query";
import { styled } from "styled-components";
import { getData } from "../api";
import { utils, writeFile } from "xlsx";

const Btn = styled.button`
  font-family: "Pretendard-SemiBold";
  background-color: #fff;
  color: #666;
  border-radius: 3px;
  padding: 0.5rem 1rem;
  border: 0;
  font-size: 0.9rem;
  cursor: pointer;
  &:hover {
    scale: 1.1;
    transition: all 0.2s ease;
  }
  @media only screen and (max-width: 600px) {
    font-size: 0.9rem;
  }
`;

interface IData {
  idx?: number;
  name?: string;
  jumin?: string;
  gender?: string;
  company?: string;
  bizno?: string;
  zipcode?: string;
  addr?: string;
  addr_extra?: string;
  tel?: string;
  mobile?: string;
  mobile_extra?: string;
  email?: string;
  relation?: string;
  child_name?: string;
  child_jumin?: string;
  child_gender?: string;
  child_mobile?: string;
  school?: string;
  grade?: string;
  hat_size?: string;
  created?: string;
  file1?: string;
  file2?: string;
}

const ExcelButton: React.FC = () => {
  const  [key, setKey] = useState<string>("");
  const { data, isLoading } = useQuery<IData[]>(["allData", key], () => getData(key));
  const handleSave = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!isLoading) {
      const excelTableHead = [
        [
          "순번",
          "이름",
          "생년월일",
          "성별",
          "직장",
          "사업자등록번호",
          "우편번호",
          "주소",
          "나머지주소",
          "전화번호",
          "휴대전화번호",
          "비상연락처",
          "이메일",
          "관계",
          "자녀이름",
          "자녀생년월일",
          "자녀성별",
          "자녀휴대폰",
          "학교",
          "학년",
          "모자사이즈",
          "신청일",
          "파일1",
          "파일2",
        ],
      ];
      const rows:string[] = [];
      [].forEach.call(data, (dt) => (
        rows.push(dt)
      ))

      console.log(rows);
      const worksheet = utils.json_to_sheet(rows);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "골든벨신청자");
      utils.sheet_add_aoa(workbook, excelTableHead, { origin: "A1" });
      writeFile(workbook, `골든벨신청자.xlsx`);
    }
  };

  return <Btn onClick={handleSave}>엑셀저장</Btn>;
};

export default ExcelButton;
