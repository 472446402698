import React from "react";
import { useQuery } from "react-query";
import { styled } from "styled-components";
import { getEvent } from "../api";
import { utils, writeFile } from "xlsx";

const Btn = styled.button`
  font-family: "Pretendard-SemiBold";
  background-color: #fff;
  color: #666;
  border-radius: 3px;
  padding: 0.5rem 1rem;
  border: 0;
  font-size: 0.9rem;
  cursor: pointer;
  &:hover {
    scale: 1.1;
    transition: all 0.2s ease;
  }
  @media only screen and (max-width: 600px) {
    font-size: 0.9rem;
  }
`;

interface IData {
  idx?: number;
  name?: string;
  jumin?: string;
  mobile?: string;
  sns?: string;
  created?: string;
}

const ExcelButton2: React.FC = () => {
  const { data, isLoading } = useQuery<IData[]>(
    "allEventData",
    getEvent
  );
  const handleSave = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!isLoading) {
      const excelTableHead = [
        [
          "순번",
          "이름",
          "생년월일",
          "휴대전화번호",
          "인스타링크",
          "신청일",
        ],
      ];
      const rows: string[] = [];
      [].forEach.call(data, (dt) => rows.push(dt));

      console.log(rows);
      const worksheet = utils.json_to_sheet(rows);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "열공응원챌린지신청자");
      utils.sheet_add_aoa(workbook, excelTableHead, { origin: "A1" });
      writeFile(workbook, `열공응원챌린지신청자.xlsx`);
    }
  };

  return <Btn onClick={handleSave}>엑셀저장</Btn>;
};

export default ExcelButton2;
