import React, { useState } from "react";
import { useQuery } from "react-query";
import { styled } from "styled-components";
import { getData } from "../api";
import DownloadLink from "./Download";
import ExcelButton from "./ExcelButton";
import LinkDelete from "./LinkDelete";
import LinkView from "./LinkView";

const Sul = styled.ul`
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  li {
    padding: 0 0 0.5rem 0;
    input {
      padding: 0.5rem;
      border: 0;
      border-radius: 2px;
    }
  }
`;

const Tul = styled.ul`
  background-color: #6ab04c;
  display: flex;
  font-size: 0.9rem;
  li {
    padding: 0.7rem;
    border-bottom: 1px solid #eee;
    text-align: center;
    color: white;
  }
  li:first-child {
    flex-basis: 5%;
  }
  li:nth-child(2),
  li:nth-child(3) {
    flex-basis: 10%;
  }
  li:nth-child(4) {
    text-align: left;
    flex-basis: 20%;
  }
  li:nth-child(5) {
    flex-basis: 18%;
  }
  li:nth-child(6) {
    flex-basis: 20%;
  }
  li:nth-child(7) {
    flex-basis: 10%;
  }
  li:nth-child(8) {
    flex-basis: 7%;
  }
`;
const Ul = styled.ul`
  background-color: #fff;
`;
const Li = styled.li`
  display: flex;
  font-size: 0.9rem;
  div {
    padding: 0.7rem;
    border-bottom: 1px solid #eee;
    text-align: center;
  }
  div:first-child {
    flex-basis: 5%;
  }
  div:nth-child(2),
  div:nth-child(3) {
    flex-basis: 10%;
  }

  div:nth-child(4) {
    text-align: left;
    flex-basis: 20%;
  }
  div:nth-child(5) {
    flex-basis: 18%;
  }
  div:nth-child(6) {
    flex-basis: 20%;
  }
  div:nth-child(7) {
    flex-basis: 10%;
  }
  div:nth-child(8) {
    flex-basis: 7%;
  }
`;

interface IData {
  순번: number;
  이름: string;
  생년월일: string;
  성별: string;
  직장명: string;
  우편번호: string;
  주소: string;
  나머지주소: string;
  전화번호: string;
  휴대전화번호: string;
  비상연락처: string;
  이메일주소: string;
  관계: string;
  자녀이름: string;
  자녀생년월일: string;
  자녀성별: string;
  자녀학교명: string;
  자녀학년: string;
  모자사이즈: string;
  접수일시: string;
  재직증명서: string;
  가족관계증명서: string;
  구분: string;
}

const DataList: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedTerm, setDebouncedTerm] = useState<string>(searchTerm);
  
  // useEffect(() => {
  //   const handler = setTimeout(() => {
  //     setDebouncedTerm(searchTerm);
  //   }, 500); // Adjust delay as needed

  //   return () => {
  //     clearTimeout(handler);
  //   };
  // }, [searchTerm]);

  const { data, error, isLoading } = useQuery<IData[], Error>({
    queryKey: ["allData", debouncedTerm],
    queryFn: () => getData(debouncedTerm),
    enabled: debouncedTerm.length > 0,
  });

  

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  let i = 0;

  return (
    <div>
      <Sul>
        <li>
          <input
            type="text"
            name="s_key"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="신청자이름 또는 휴대전화번호"
          />
          <button
            style={{
              padding: "0.5rem 1rem",
              border: "0",
              backgroundColor: "#6ab04c",
              color: "white",
            }}
            onClick={(e) => setDebouncedTerm(searchTerm)}
          >
            찾기
          </button>
        </li>
        <li>
          <ExcelButton />
        </li>
      </Sul>
      <Tul>
        <li>순번</li>
        <li>신청자</li>
        <li>자녀이름</li>
        <li>직장</li>
        <li>휴대전화</li>
        <li>접수일</li>
        <li>첨부파일</li>
        <li></li>
      </Tul>
      <Ul>
        {data?.map((item) => {
          i++;

          return (
            <Li key={item.순번}>
              <div>{item.구분 === "" ? i : item.구분}</div>
              <div>{item.이름}</div>
              <div>{item.자녀이름}</div>
              <div style={{ overflow: "hidden" }}>
                <LinkView
                  key={item.순번}
                  idx={item.순번}
                  company={item.직장명}
                />
              </div>
              <div>{item.휴대전화번호}</div>
              <div>{item.접수일시?.substring(0, 16)}</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                {item.재직증명서 ? (
                  <DownloadLink
                    url={`https://www.ulsangoldenbell.com/files/${item.재직증명서}`}
                    fileName={item.재직증명서}
                    fileTitle="재직증명서"
                  />
                ) : (
                  ""
                )}
                {item.가족관계증명서 ? (
                  <DownloadLink
                    url={`https://www.ulsangoldenbell.com/files/${item.가족관계증명서}`}
                    fileName={item.가족관계증명서}
                    fileTitle="가족관계증명서"
                  />
                ) : (
                  ""
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LinkDelete idx={item.순번} />
              </div>
            </Li>
          );
        })}
      </Ul>
    </div>
  );
};

export default DataList;
