import React from "react";
import { useResetRecoilState } from "recoil";
import { userState } from "../atom";
import { styled } from "styled-components";


const Btn = styled.button`
  font-family: "Pretendard-SemiBold";
  background-color: #fff;
  color: #666;
  border-radius: 16px;
  height: 32px;
  border: 0;
  font-size: 1rem;
  cursor: pointer;
  &:hover {
    scale: 1.1;
    transition: all 0.2s ease;
  }

  @media only screen and (max-width: 600px) {
    font-size: 0.9rem;
  }
`;

const LogoutButton: React.FC = () => {
  const resetUser = useResetRecoilState(userState);

  const handleLogout = () => {
    resetUser();
    localStorage.removeItem('nm');
    localStorage.removeItem("level");
  };

  return <Btn onClick={handleLogout}>로그아웃</Btn>;
};

export default LogoutButton;
