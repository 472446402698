import { Helmet, HelmetProvider } from "react-helmet-async";
import { styled } from "styled-components";
import { Title, ContWrapper, Cont } from "../assets/styles/StyleComponent";

const Left = styled.div`
  font-family: "Pretendard-Bold";
  font-size: 1.15rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 200%;
  color: #e77575;
`;
const Right = styled.div`
  line-height: 200%;
  padding-left: 0px;
  color: #333;
`;
const Table = styled.table`
  font-size: 0.9rem;
  min-width: 420px;
  th, td {
    padding: 0.5rem;
    border: solid 1px #aaa;
    text-align: center;
    vertical-align: middle;
    line-height: 1.2rem;
  }
  th {
    background-color: #EEE;
  }
    td {
    background-color: #fff;
    }
`;
const TableWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
  @media only screen and (max-width: 600px) {
    max-width: 310px;
  }
`;

function Overview() {
  return (
    <HelmetProvider>
      <>
        <Helmet>
          <title>행사개요</title>
        </Helmet>
        <Title>행사개요</Title>
        <ContWrapper>
          <Cont>
            <Left>행사명</Left>
            <Right>제8회 자녀와 함께하는 울산산업골든벨</Right>
          </Cont>
          <Cont>
            <Left>일시 / 장소</Left>
            <Right>2024년 10월 12일 (토) 14:00 / UNIST 실내체육관</Right>
          </Cont>
          <Cont>
            <Left>참여대상</Left>
            <Right>
              울산 소재 사업장 근로자(부모)와 울산 소재 초등학교 재학생(자녀)
              <br />※ 울산소재 개인사업자 및 공무원 (교육공무원 포함) 참여가능
            </Right>
          </Cont>
          <Cont>
            <Left>참여자수</Left>
            <Right>
              선착순 100개 팀 / 근로자 (1명)+자녀 (1명)
              <br />※ 100개 팀은 참여 확정팀이며,예비명단 10개 팀 포함 110개
              팀을 접수받습니다.
              <br />※ 예비명단 10개 팀은 항후 참여 확정팀에서 불참팀이 발생시
              대체 참여됩니다.
            </Right>
          </Cont>
          <Cont>
            <Left>참여제한</Left>
            <Right>
              - 역대 우승팀 및 2023년도 준우승팀
              <br />- 공무원·교사(강사, 교수 등 교육직 포함)는 전체 참가자 중
              20팀 이내로 참여 제한
            </Right>
          </Cont>
          <Cont>
            <Left>대회방식</Left>
            <Right>
              당일 예선 후 본선 진행(최종 1팀에게 골든벨 기회 부여) <br />※ 세부
              대회방식은 모집완료 후 상세 통보 예정
            </Right>
          </Cont>
          <Cont>
            <Left>출제범위 </Left>
            <Right>
              울산산업 역사이야기 (별도 자료집 제공) <br />※ 선착순 접수마감 후
              일괄 신청 주소지로 우편발송{" "}
            </Right>
          </Cont>
          <Cont>
            <Left>접수기간 </Left>
            <Right>
              2024년 8월 23일 (금) 9시 ~ 선착순 마감
              <br />
              (예비명단 포함 110개 팀){" "}
            </Right>
          </Cont>
          <Cont>
            <Left>접수처</Left>
            <Right>
              제8회 울산산업골든벨 홈페이지(www.ulsangoldenbell.com)
            </Right>
          </Cont>
          <Cont>
            <Left>시상</Left>
            <Right>
              <TableWrapper>
                <Table>
                  <tr>
                    <th>상급</th>
                    <th>시상규모</th>
                    <th>장학금</th>
                    <th>비고</th>
                  </tr>
                  <tr>
                    <td>골든벨(우승)</td>
                    <td>1개 팀</td>
                    <td>200만 원</td>
                    <td>
                      울산광역시 교육감상
                      <br />
                      골든벨 문제 틀릴 시 100만 원
                    </td>
                  </tr>
                  <tr>
                    <td>우등상</td>
                    <td>11개 팀</td>
                    <td>각 30만 원</td>
                    <td>울산상공회의소 회장상</td>
                  </tr>
                </Table>
                * 참가팀 전원 기념품 제공
              </TableWrapper>
            </Right>
          </Cont>
        </ContWrapper>
      </>
    </HelmetProvider>
  );
}

export default Overview;
