import { Helmet, HelmetProvider } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Title } from "../assets/styles/StyleComponent";
import { userState } from "../atom";
import DataList from "../components/DataList";



function Login() {
  const user = useRecoilValue(userState);
  let navigate = useNavigate();

  return (
    <HelmetProvider>
      <>
        <Helmet>
          <title>접수관리</title>
        </Helmet>
        <Title>접수관리</Title>
        {!user ? (
          navigate("/login")
        ) : (
          <form>
            <DataList />
          </form>
        )}
      </>
    </HelmetProvider>
  );
}

export default Login;
