// import axios from "axios";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { checkModify, getDataOne } from "../api";
import { Cont, ContWrapper } from "../assets/styles/StyleComponent";

const Left = styled.div`
  font-family: "Pretendard-Bold";
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 200%;
  color: #e77575;
`;
const Right = styled.div`
  line-height: 200%;
  padding-left: 0px;
  color: #333;
`;
const Input = styled.input`
  padding: 8px;
  border: 1px solid silver;
  border-radius: 4px;
  font-size: 1rem;
  max-width: 100%;
`;

const Btn = styled.button`
  border: 1px solid green;
  background-color: green;
  padding: 0.5rem 1rem;
  margin: 24px 0;
  border-radius: 4px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
`;

const Box = styled.div`
  background-color: #fff;
  color: #333;
  border-radius: 16px;
  height: 400px;
  margin-bottom: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 600px) {
    padding: 4px 20px;
  }
`;

type FormData = {
  idx: string;
  file: File | null;
  file2: File | null;
};

interface ModifyProps {
  idx: number;
}

function ReceiptModify({ idx }: ModifyProps) {
  const [formData, setFormData] = useState<FormData>({
    idx: "",
    file: null,
    file2: null,
  });
  const [upfile, setUpFile] = useState<any | null>(null);
  const [upfile2, setUpFile2] = useState<any | null>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [timeOk, setTimeOk] = useState<boolean>(true);
  const openTime = new Date("2024-08-23T09:00:00");
  const [modifyIdx, setModifyIdx] = useState<number>(idx);

  //   const [idx] = this.props;
  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      setTimeOk(openTime < now);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    // console.log(modifyIdx);
    getDataOne(modifyIdx).then((data) => {
      //   console.log(data);
      const bno = data.res.bizno.split("-");
      //   console.log(bno);
      setFormData({
        ...formData,
        idx: data.res.idx,
      });
    });
  }, []);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (e.target.id === "file") {
        setFormData({
          ...formData,
          file: e.target.files[0],
        });
        setUpFile(e.target.files[0]);
      } else if (e.target.id === "file2") {
        setFormData({
          ...formData,
          file2: e.target.files[0],
        });
        setUpFile2(e.target.files[0]);
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newData = new FormData();
    newData.append("idx", formData.idx);
    newData.append("file", upfile);
    newData.append("file2", upfile2);

    try {
      // console.log(JSON.stringify(formData));
      // console.log(upfile, upfile2, newData);
      //   const response = await fetch(
      //     "http://2024.ulsangoldenbell.com/api/post4.php",
      //     {
      //       method: "POST",
      //       body: newData,
      //     }
      //   );
      //   const data = await response.json();
      //   if (response.ok) {
      //     // console.log(data.data);
      //     if (data.data.reason === "exist") {
      //       alert("이미 신청되어 있습니다.");
      //     } else {
      //       // setStatus("Data submitted successfully");
      //       console.log(data.data);
      //       setSubmitted(true);
      //     }
      //   }
      checkModify(formData.idx, upfile, upfile2).then((data) => {
        setSubmitted(true);
      });
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };  

  return (
    <>
      {submitted ? (
        <Box>수정되었습니다. 감사합니다.</Box>
      ) : (
        <form onSubmit={handleSubmit}>
          <ContWrapper>
            <Cont style={{ marginTop: "48px" }}>
              <div
                style={{
                  fontFamily: "Pretendard-ExtraBold",
                  fontSize: "1.1rem",
                  color: "royalblue",
                }}
              >
                첨부파일
              </div>
              <hr />
            </Cont>
            <Cont>
              <Left>재직증명서(개인사업자는 사업자등록증)</Left>
              <Right>
                <Input
                  type="file"
                  name="file"
                  id="file"
                  onChange={handleFileChange}
                  required
                  style={{ width: "100%" }}
                />
              </Right>
            </Cont>
            <Cont>
              <Left>가족관계증명서(또는 주민등록등본)</Left>
              <Right>
                <Input
                  type="file"
                  name="file2"
                  id="file2"
                  onChange={handleFileChange}
                  required
                  style={{ width: "100%" }}
                />
              </Right>
            </Cont>

            <Cont style={{ textAlign: "center", margin: "20px 0" }}>
              <Btn type="submit">수정하기</Btn>
            </Cont>
          </ContWrapper>
        </form>
      )}
    </>
  );
}

export default ReceiptModify;
